import React, { useEffect, useState } from 'react';

import Footer from './Footer';
import SideBar from './Sidebar';

import '../assets/sass/main.scss';

export default function Layout({ children, fullMenu, langKey }) {
  const [preloaded, setPreloaded] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setPreloaded(false), 100);
    return () => clearTimeout(timeoutId);
  });

  return (
    <div className={preloaded ? ' main-body  is-preload' : ' main-body'}>
      <div id="page-wrapper">
        <SideBar fullMenu={fullMenu} {...{langKey}} />
        {children}
        <Footer {...{langKey}} />
      </div>
    </div>
  );
}
