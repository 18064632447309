import React from 'react';
import { Link } from 'gatsby';

export function langPath(langKey, path) {
  const noTrailingSlash = path === '/' ? '' : path
  return langKey === 'en' ? path : `/${langKey}${noTrailingSlash}`;
}

export default function Nav({ onMenuToggle = () => {}, langKey }) {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>

        <ul className="links">
          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/')}
            >
              {langKey === 'en' && <>Home</>}
              {langKey === 'vi' && <>Trang chủ</>}
            </Link>
          </li>

          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/services')}
            >
              {langKey === 'en' && <>Our services</>}
              {langKey === 'vi' && <>Các dịch vụ của chúng tôi</>}
            </Link>
          </li>

          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/how-we-do')}
            >
              {langKey === 'en' && <>How we do</>}
              {langKey === 'vi' && <>Chúng tôi có gì?</>}
            </Link>
          </li>

          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/projects')}
            >
              {langKey === 'en' && <>Our past projects</>}
              {langKey === 'vi' && <>Các dự án chúng tôi đã làm</>}
            </Link>
          </li>

          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/about')}
            >
              {langKey === 'en' && <>About us</>}
              {langKey === 'vi' && <>Giới thiệu về công ty</>}
            </Link>
          </li>

          <li>
            <Link
              onClick={onMenuToggle}
              to={langPath(langKey, '/contact')}
            >
              {langKey === 'en' && <>Contact us</>}
              {langKey === 'vi' && <>Hãy liên hệ với chúng tôi</>}
            </Link>
          </li>
        </ul>

        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}
