import React from 'react';

import Layout from '../../components/Layout';
import Projects from '../../components/Projects';

const ProjectsPage = ({pageContext: {langKey}}) => (
  <Layout fullMenu {...{langKey}}>
    <section id="banner">
      <div className="inner">
        <h2>
          {langKey === 'en' && <>Our past projects</>}
          {langKey === 'vi' && <>Các dự án chúng tôi đã làm</>}
        </h2>
      </div>
    </section>

    <section id="wrapper">
      <div className="wrapper">
        <div className="inner">
          <Projects {...{langKey}} />
        </div>
      </div>
    </section>
  </Layout>
);

export default ProjectsPage;
export { Head } from '../../components/Head';
