import React from 'react';
import config from '../../config';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
export function Head() {
  return (
    <>
      <title>{config.siteTitle}</title>
      <meta name="description" content={config.siteTitle} />
      <meta name="keywords" content="website" />
    </>
  )
}
