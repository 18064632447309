import React from 'react';

export default function Contact({withHeader, langKey}) {
  return (
    <>
      {withHeader && <h2 className="major">
        {langKey === 'en' && <>Contact us</>}
        {langKey === 'vi' && <>Hãy liên hệ với chúng tôi</>}
      </h2>}

      <ul className="contact">
        <li className="fa-home">25 Oxford Ave, Bankstown NSW 2200</li>

        <li className="fa-phone">0430 466 462</li>

        <li className="fa-facebook">
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Future-Development-Constructions-Pty-Ltd/100085695083887/">
            {langKey === 'en' && <>View our Facebook page</>}
            {langKey === 'vi' && <>Xem trang Facebook của chúng tôi</>}
          </a>
        </li>

        <li className="fa-envelope-o">
          your.general.enquiries@gmail.com
        </li>
      </ul>

      <form method="post" action="https://formspree.io/f/mlevjqgg">
        <div className="fields">
          <div className="field">
            <label htmlFor="name">
              {langKey === 'en' && <>Your name:</>}
              {langKey === 'vi' && <>Tên của bạn:</>}
            </label>

            <input type="text" name="name" id="name" />
          </div>

          <div className="field">
            <label htmlFor="email">
              {langKey === 'en' && <>Your email:</>}
              {langKey === 'vi' && <>Email của bạn:</>}
            </label>

            <input type="email" name="email" id="email" />
          </div>

          <div className="field">
            <label htmlFor="tel">
              {langKey === 'en' && <>Your phone number:</>}
              {langKey === 'vi' && <>Số điện thoại của bạn:</>}
            </label>

            <input type="text" name="tel" id="tel" />
          </div>

          <div className="field">
            <label htmlFor="message">
              {langKey === 'en' && <>Message to contact us:</>}
              {langKey === 'vi' && <>Bạn muốn chúng tôi giúp gì?</>}
            </label>

            <textarea name="message" id="message" rows="4"></textarea>
          </div>
        </div>

        <ul className="actions">
          <li>
            <input
              type="submit"
              value={
                langKey === 'vi'
                  ? 'Gửi'
                  : 'Send Message'
              }
            />
          </li>
        </ul>
      </form>
    </>
  );
}
