import React, { useState } from 'react';
import { Link } from 'gatsby';

import LangSwitch from './LangSwitch';
import Nav, { langPath } from './Nav';


export default function SideBar({ fullMenu, langKey }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <h1>
          <Link to={langPath(langKey, '/')}>Future Development Constructions</Link>
        </h1>

        <LangSwitch />

        <nav>
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
        </nav>
      </header>

      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} {...{langKey}} />
      </div>
    </>
  );
}
