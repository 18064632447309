import React from 'react';

import { langPath } from './Nav';

import house from '../assets/images/house/Elegantfoto-5A.jpg';
import duplex from '../assets/images/duplex/Elegantfoto-8297A.jpg';
import granny from '../assets/images/granny/Elegantfoto-22A.jpg';

function ProjectsBody({langKey}) {
  return (
    <section className="features">
      <article>
        <a href={langPath(langKey, '/projects/house')} className="image">
          <img src={house} alt="" />
        </a>

        <h3 className="major">
          {langKey === 'en' && <>Houses</>}
          {langKey === 'vi' && <>Nhà</>}
        </h3>

        <a href={langPath(langKey, '/projects/house')} className="special">
          {langKey === 'en' && <>Learn more</>}
          {langKey === 'vi' && <>Xem thêm</>}
        </a>
      </article>

      <article>
        <a href={langPath(langKey, '/projects/duplex')} className="image">
          <img src={duplex} alt="" />
        </a>

        <h3 className="major">
          {langKey === 'en' && <>Duplexes</>}
          {langKey === 'vi' && <>Duplex</>}
        </h3>

        <a href={langPath(langKey, '/projects/duplex')} className="special">
          {langKey === 'en' && <>Learn more</>}
          {langKey === 'vi' && <>Xem thêm</>}
        </a>
      </article>

      <article>
        <a href={langPath(langKey, '/projects/granny')} className="image">
          <img src={granny} alt="" />
        </a>

        <h3 className="major">
          {langKey === 'en' && <>Granny flats</>}
          {langKey === 'vi' && <>Granny flat</>}
        </h3>

        <a href={langPath(langKey, '/projects/granny')} className="special">
          {langKey === 'en' && <>Learn more</>}
          {langKey === 'vi' && <>Xem thêm</>}
        </a>
      </article>
    </section>
  )
};

export default function Projects({withHeading, langKey}) {
  return (
    <>
      {withHeading && <h2 className="major">
        {langKey === 'en' && <>Our past projects</>}
        {langKey === 'vi' && <>Các dự án chúng tôi đã làm</>}
      </h2>}

      <ProjectsBody {...{langKey}} />
    </>
  )
}
