import React from 'react';

export default function Copyright() {
  return (
    <ul className="copyright">
      <li>&copy; Future Development Constructions Pty Ltd. All rights reserved.</li>
      <li>
        Design: <a target="_blank" rel="noreferrer" href="https://toxgam.com">Toxgam</a>
      </li>
    </ul>
  );
}
