module.exports = {
  siteTitle: 'Future Development Constructions Pty Ltd',
  manifestName: 'Future Development',
  manifestShortName: 'Future Dev', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  pathPrefix: '/', // This path is subpath of your hosting https://domain/portfolio
  heading: 'Future Development Constructions Pty Ltd',
  subHeading: 'House building services'
};
