import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import au from '../assets/images/langs/au.svg';
import vn from '../assets/images/langs/vn.svg';

import '../assets/sass/lang-switch.scss';

// Scrolling down will fade out the languages switch
// Idea from: https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
const ID = 'lang-switch';
const DISPLAY_RANGE = 500

export default function LangSwitch() {
  useEffect(() => {
    window.onscroll = () => {
      const opacity = Math.max(0, (DISPLAY_RANGE - window.pageYOffset) / DISPLAY_RANGE);
      document.getElementById(ID).style.opacity = '' + opacity;
      document.getElementById(ID).style.display = opacity < 0.01 ? 'none' : 'block';
    }
  });

  return (
    <div id={ID}>
      <div>
        <Link to="/">
          <img src={au} alt="English" />
        </Link>
      </div>

      <div>
        <Link to="/vi">
          <img src={vn} alt="Vietnamese" />
        </Link>
      </div>
    </div>
  );
}
