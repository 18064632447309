import React from 'react';

import Contact from './Contact';
import Copyright from './Copyright';

export default function Footer({langKey}) {
  return (
    <section id="footer" className="wrapper alt">
      <div className="inner">
        {typeof window !== 'undefined' && !window.location.pathname.includes('/contact') &&
          <Contact withHeader {...{langKey}} />
        }

        <Copyright />
      </div>
    </section>
  );
}
